.legend {
    outline: solid;
    outline-width: 1px;
    padding: 0.5em;
    width: max-content;
  }
  
  .legend-title {
    padding: 1em 1em 1em 0em;
  }
  
  .legend-content {
    display: grid;
    row-gap: 8px;
    column-gap: 8px;
    grid-template-columns: auto auto;
    align-items: center;
  }
  
  .legend-color-shape {
    display: inline-block;
    width: 1em;
    height: 1em;
  }