.viewer-container {
  height: calc(100vh - 50px);
}

.landmark-table {
  font-size: 6px;
  width: 60%;

  > tbody {
    > tr:not(:first-child):hover { // ignore first row, focus hover item
      background-color: skyblue;
      cursor: pointer;
    }
  }

  > td, th {
    border: 1px solid black;
    text-align: left;
    padding: 5px;
  }
}

.landmark-widget-table {
  font-size: 10px;
  width: 80%;

  > tbody {
    > tr:not(:first-child):hover { // ignore first row, focus hover item
      background-color: skyblue;
      cursor: pointer;
    }
  }

  > td, th {
    border: 2px solid black;
    text-align: left;
    padding: 7px;
  }
}
