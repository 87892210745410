.header {
  background-color: #282c34;

  .buttonContainer {
    text-align: center;

    button {
      width: 100px;
      margin: 10px !important;
    }
  }
}
